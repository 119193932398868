import styled from "styled";

export const Centered = styled.div`
  text-align: center;
`;

export const Body = styled.div`
  max-width: ${props => props.theme.maxWidth}px;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  padding: 80px 15px 0;
`;
