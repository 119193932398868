import React from "react";

import { Centered } from "./components/Layout";
import Logo, { LogoTitle, LogoNote } from "./components/Logo";

import chef from "./svg/chef.svg";

export default () => {
  return (
    <Centered>
      <Logo src={chef} height={70} />
      <LogoTitle>Let's cook!</LogoTitle>
      <LogoNote>
        Utilisez la barre de recherche pour trouver votre recette...
      </LogoNote>
    </Centered>
  );
};
