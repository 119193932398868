import React from "react";
import styled from "styled";

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #00817b;
`;

const AvatarPicture = styled.img`
  margin: 2px;
  width: calc(100% - 4px);
  border-radius: 50%;
`;

interface AvatarProps {
  src: string;
}

export default function(props: AvatarProps) {
  return (
    <Avatar>
      <AvatarPicture src={props.src} />
    </Avatar>
  );
}
