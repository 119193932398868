import styled from "styled";

export const Logo = styled.img`
  margin-bottom: 15px;
`;

export const LogoTitle = styled.h4`
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 5px;
`;

export const LogoNote = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  color: gray;
`;

export default Logo;
