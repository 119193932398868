import React, { ChangeEvent } from "react";
import styled from "styled";
import Icon from "./Icon";

const InputIcon = styled(Icon)`
  color: #999;
  width: 0;
  position: relative;
  left: 5px;
  bottom: 1px;
`;

const InputContainer = styled.div`
  flex: 3;
  margin: 0 15px;
  white-space: nowrap;
`;

const Input = styled.input`
  width: calc(100% - 30px);
  border: none;
  border-bottom: 1px solid lightgray;
  line-height: 35px;
  font-size: 17px;
  color: gray;
  padding-left: 30px;
  transition: border-bottom-color 0.5s ease;

  :focus {
    outline: none;
  }
`;

interface InputProps {
  icon: string;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function(props: InputProps) {
  return (
    <InputContainer>
      <InputIcon icon="fa-search" />
      <Input
        placeholder="Rechercher..."
        value={props.value}
        onChange={props.onChange}
      />
    </InputContainer>
  );
}
