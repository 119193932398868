import { History } from "history";
import { rgba } from "polished";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import styled from "styled";

import { useDebouncedValue } from "./hooks";

import Brand from "./components/Brand";
import Spacer from "./components/Spacer";
import Avatar from "./components/Avatar";
import Input from "./components/Input";

const StyledHeader = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;

  background: white;

  box-shadow: 0 0 5px 2px ${rgba("#000", 0.15)};
`;

const Container = styled.div`
  max-width: ${props => props.theme.maxWidth}px;
  height: 60px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  content: "Foodotron";

  display: flex;
  align-items: center;
`;

export default withRouter(function({ history }: { history: History }) {
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebouncedValue(query, 500);

  useEffect(
    () =>
      void (
        debouncedQuery.length > 0 &&
        history.push(`/search?q=${encodeURIComponent(debouncedQuery)}`)
      ),
    [history, debouncedQuery]
  );

  return (
    <StyledHeader>
      <Container>
        <Brand to="/" />
        <Spacer weight={1} />
        <Input
          icon="fa fa-search"
          placeholder="Rechercher..."
          value={query}
          onChange={e => setQuery(e.target.value.trim())}
        />
        <Spacer weight={1} />
        <Avatar src="/avatar.png" />
      </Container>
    </StyledHeader>
  );
});
