import { useEffect, useState } from "react";

export function useDebouncedValue<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timeout);
  }, [delay, value]);

  return debouncedValue;
}

export interface CacheStorage<T> {
  has(uri: string): boolean;
  get(uri: string): T;
  declare(uri: string, data: T): void;
}
export function useAPICall<T>(
  uri: string,
  cache?: CacheStorage<T>
): [true, null, null] | [false, T, number | null] {
  const [result, setResult] = useState<T | null>(
    cache && cache.has(uri) ? cache.get(uri) : null
  );
  const [loading, setLoading] = useState(cache ? !cache.has(uri) : true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const currentUri = uri;
    (() => {
      if (cache && cache.has(currentUri))
        return Promise.resolve(cache.get(uri));
      else {
        setLoading(true);
        return fetch(uri).then(res => {
          if (res.status >= 200 && res.status < 400) return res.json();
          else return Promise.reject(res.status);
        });
      }
    })()
      .then(data => {
        if (uri !== currentUri) return;
        if (cache) cache.declare(currentUri, data);
        setResult(data);
        setLoading(false);
      })
      .catch(code => setError(code));
    return () => {};
  }, [cache, uri]);

  if (loading || result === null) return [true, null, error];
  else return [loading, result, error];
}

type MaybeNull<T> = T | null;

export class SimpleCache<T> implements CacheStorage<T> {
  previousUri: string = "";
  previousData: MaybeNull<T> = null;
  scrollHeight: number = 0;

  has(uri: string) {
    return this.previousUri === uri;
  }

  get(): T {
    return this.previousData as T;
  }

  declare(uri: string, data: T) {
    this.previousData = data;
    this.previousUri = uri;
  }
}
