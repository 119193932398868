import React from "react";

import { AnimatedWord } from "./components/Animation";
import { Centered } from "./components/Layout";
import Logo, { LogoTitle, LogoNote } from "./components/Logo";

import stove from "./svg/stove.svg";
import fire from "./svg/cooking-pot-with-fire.svg";

export const GenericError = ({ code = 500 }: { code?: number }) => {
  return (
    <Centered>
      <Logo src={fire} height={70} />
      <LogoTitle>{code && `Erreur ${code} : `}Ça sent le brûlé...</LogoTitle>
      <LogoNote>
        Une erreur (à laquelle on ne s'attendait pas du tout) nous barre la
        route de la cuisine...
      </LogoNote>
    </Centered>
  );
};

export const NoMatch = () => {
  return (
    <Centered>
      <Logo src={stove} height={80} />
      <LogoNote>Oups...</LogoNote>
      <LogoTitle>
        404 : Not&nbsp;
        <AnimatedWord
          alternate={true}
          duration={2}
          from="Found"
          to="Cooked"
          width={70}
        />
      </LogoTitle>
    </Centered>
  );
};
