import React, { ReactChild } from "react";
import { match as Match } from "react-router";
import { useAPICall } from "./hooks";
import LoaderComponent from "./components/Loader";
import styled from "styled";

import { GenericError } from "./Errors";
import DocumentTitle from "react-document-title";

type Recipe = {
  title: string;
  link: string;
  rating: number;
  ingredients: Array<string>;
  steps: Array<string>;
  pictures: Array<string>;
};

const ListItem = styled.li`
  margin-bottom: 15px;
`;

interface ImageProps {
  weight?: number;
  src: string;
}

const Image = styled.div<ImageProps>`
  flex: ${props => props.weight || 1};
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: center;
`;

const PictureGrid = styled.div`
  margin: 0 -15px 0;
  display: flex;
  height: 30vh;
  position: relative;
`;

interface ColumnProps {
  flex?: number;
}

const Column = styled.div<ColumnProps>`
  flex: ${props => props.flex || 1};
  display: flex;
  flex-direction: column;
`;

const FaIcon = ({ icon, className }: { icon: string; className?: string }) => (
  <i className={`fa ${icon} ${className}`} aria-hidden="true" />
);

const Title = styled.div`
  text-align: center;
  margin: 15px 0;
  margin-top: -50px;
  position: relative;

  & > h2 {
    min-width: 50%;
    margin-bottom: 0px;
    display: inline-block;
    border-radius: 30px;
    background: white;
    padding: 15px 20px;
  }
`;

const Rating = styled(
  ({
    value,
    className,
    total = 5
  }: {
    value: number;
    className?: string;
    total?: number;
  }) => (
    <div className={className}>
      {Array.from({ length: total }).map((_, i) => (
        <FaIcon key={i} icon={`fa-star${i < value ? "" : "-o"}`} />
      ))}
      &nbsp;{value}/{total}
    </div>
  )
)`
  color: #00817b;
  text-align: center;
  margin: 10px 0;
`;

const Note = styled.div`
  color: gray;
  text-align: center;
  font-size: 15px;
`;

const ColSplit = styled.ul`
  @media screen and (min-width: 400px) {
    column-count: 3;
  }
`;

const DetailsCard = styled.div`
  background-color: white;
  margin: -20px -15px 0;
  padding: 0 15px 15px;

  @media screen and (min-width: 400px) {
    border-radius: 2px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    margin: 0 15px 20px;
  }
`;

const Headline = styled(
  ({ className, children }: { className?: string; children?: ReactChild }) => (
    <h3 className={className}>
      <span>{children}</span>
    </h3>
  )
)`
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
  height: 12px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  & > span {
    background: white;
    padding: 0 15px;
  }
`;

export function RecipeDetails({
  match
}: {
  match: Match<{ recipeLink: string }>;
}) {
  const [isLoading, recipe, errorCode] = useAPICall<Recipe>(
    `/api/marmiton/${match.params.recipeLink}`
  );

  if (errorCode) return <GenericError code={errorCode} />;
  if (isLoading || !recipe) return <LoaderComponent />;
  return (
    <DocumentTitle title={`${recipe.title} | Foodotron`}>
      <DetailsCard>
        <PictureGrid>
          <Image src={recipe.pictures[0]} weight={2} />
          <Column>
            <Image src={recipe.pictures[1]} />
            <Image src={recipe.pictures[2]} />
          </Column>
        </PictureGrid>
        <Title>
          <h2>{recipe.title}</h2>
        </Title>
        <Rating value={recipe.rating} />
        <Note>Préparation : 20 minutes</Note>

        <div>
          <Headline>Ingrédients</Headline>
          <ColSplit>
            {recipe.ingredients.map((label, i) => (
              <ListItem key={i}>{label}</ListItem>
            ))}
          </ColSplit>
        </div>

        <div>
          <Headline>Préparation</Headline>
          <ol>
            {recipe.steps.map((desc, i) => (
              <ListItem key={i}>{desc}</ListItem>
            ))}
          </ol>
        </div>
      </DetailsCard>
    </DocumentTitle>
  );
}
