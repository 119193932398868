import styled from "styled";

interface AnimatedWordProps {
  width: number;
  from: string;
  to: string;
  duration: number;
  alternate?: boolean;
}

export const AnimatedWord = styled.span<AnimatedWordProps>`
  ::before {
    display: inline-block;
    width: ${props => props.width}px;
    text-align: left;
    content: '${props => props.from}';
    animation: update ${props => props.duration}s linear infinite ${props =>
  props.alternate ? "alternate" : ""};
  }

  @keyframes update {
    0% {
    content: '${props => props.from}';
    }
    50% {
      color: transparent;
    }
    100% {
    content: '${props => props.to}';
    }
  }
`;
