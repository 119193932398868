import React from "react";
import DocumentTitle from "react-document-title";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";

import { NoMatch, GenericError } from "./Errors";
import Header from "./Header";
import Home from "./Home";
import { Body } from "./components/Layout";
import { RecipeDetails } from "./RecipeDetails";
import RecipeSearch from "./RecipeSearch";
import theme from "./theme";

export default function App() {
  return (
    <DocumentTitle title="Foodotron | Recettes & Cie">
      <Router>
        <ThemeProvider theme={theme}>
          <Body>
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/search" component={RecipeSearch} />
              <Route path="/marmiton/:recipeLink" component={RecipeDetails} />
              <Route path="/erreur" component={GenericError} />
              <Route component={NoMatch} />
            </Switch>
          </Body>
        </ThemeProvider>
      </Router>
    </DocumentTitle>
  );
}
