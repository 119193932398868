import React, { useEffect } from "react";
import { Location, History } from "history";
import styled from "styled";
import DocumentTitle from "react-document-title";

import { useAPICall, SimpleCache } from "./hooks";
import LoaderComponent from "./components/Loader";

const CoverContainer = styled.div`
  height: 150px;
  margin: -10px;
  margin-bottom: 15px;
  overflow: hidden;
`;

interface CoverProps {
  url: string;
}

const Cover = styled.div<CoverProps>`
  background-image: url('${props => props.url}');
  border-radius: 2px 2px 0 0;
  background-position: center;
  background-size: cover;
  height: 150px;
  width: 100%;
`;

const StyledRecipeCard = styled.li`
  cursor: pointer;
  font-size: 12px;
  border-radius: 2px;
  text-align: left;
  display: inline-block;
  margin: 10px;
  width: 250px;
  padding: 10px;
  transition: box-shadow 0.2s ease;
  background-color: white;
  overflow: hidden;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);

  :hover {
    box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 400px) {
    width: calc(100% - 30px) !important;
    margin: 0 0 15px !important;
  }
`;

const FaIcon = ({ icon, className }: { icon: string; className?: string }) => (
  <i className={`fa ${icon} ${className}`} aria-hidden="true" />
);

const Rating = styled(
  ({
    value,
    className,
    total = 5
  }: {
    value: number;
    className?: string;
    total?: number;
  }) => (
    <div className={className}>
      {Array.from({ length: total }).map((_, i) => (
        <FaIcon key={i} icon={`fa-star${i < value ? "" : "-o"}`} />
      ))}
      &nbsp;{value}/{total}
    </div>
  )
)`
  color: #00817b;
`;

const RecipeTitle = styled.h3`
  color: #333;
  margin: 5px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  overflow: hidden;

  background: white;
  padding: 8px 15px;
  padding-left: 15px;
  display: inline-block;
  border-radius: 200px;
  max-width: calc(100% - 20px);
  position: relative;
  top: -5px;
  left: -25px;
  padding-left: 25px;
  position: relative;
  margin-top: -50px;
`;

type Recipe = {
  title: string;
  rating: number;
  image: string;
  duration: string;
  link: string;
};

const Note = styled.span`
  color: gray;
`;

const Separator = styled.hr`
  border-width: 1px;
  border-bottom: 0;
  margin: 8px -10px;
  border-color: rgba(0, 0, 0, 0.2);
`;

interface RecipeCardProps extends Recipe {
  onClick?: (recipe: Recipe) => void;
}

const RecipeCard = (recipe: RecipeCardProps) => {
  return (
    <StyledRecipeCard onClick={() => recipe.onClick && recipe.onClick(recipe)}>
      <CoverContainer>
        <Cover url={recipe.image} />
      </CoverContainer>
      <RecipeTitle>{recipe.title}</RecipeTitle>
      <Rating value={recipe.rating} />
      <Separator />
      <Note>Préparation : {recipe.duration}</Note>
    </StyledRecipeCard>
  );
};

const RecipeList = styled.ul`
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0;
  text-align: center;
`;

const cache = new SimpleCache<Array<Recipe>>();

export default function({
  location,
  history
}: {
  location: Location;
  history: History;
}) {
  useEffect(() => {
    window.scrollTo(0, cache.scrollHeight || 0);
    return () => void (cache.scrollHeight = window.pageYOffset);
  }, [location.search]);
  const params = new URLSearchParams(location.search.slice(1));
  const [isLoading, recipes] = useAPICall<Array<Recipe>>(
    `/api/search?${new URLSearchParams({ q: params.get("q")! }).toString()}`,
    cache
  );

  return (
    <DocumentTitle title={`Recherche : ${params.get("q")} | Foodotron`}>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <RecipeList>
          {(recipes as Array<Recipe>).map(recipe => (
            <RecipeCard
              {...recipe}
              onClick={() => history.push(`/marmiton/${recipe.link}`)}
            />
          ))}
        </RecipeList>
      )}
    </DocumentTitle>
  );
}
