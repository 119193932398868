import styled from "styled";

interface SpacerProps {
  weight: number;
  break?: number;
}

export default styled.div<SpacerProps>`
  flex: ${props => props.weight};

  @media screen and (max-width: ${props => props.break || 400}px) {
    display: none;
  }
`;
