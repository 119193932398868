import React from "react";
import styled from "styled";
import fork from "../svg/fork.svg";

export const Loader = styled.img`
  margin-bottom: 25px;
  animation: prick-fork infinite 1s linear;
  @keyframes prick-fork {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 50px);
    }
  }
`;

export const LoaderContainer = styled.div`
  text-align: center;
`;

export default () => (
  <LoaderContainer>
    <Loader src={fork} height={100} />
    <h4>Chargement...</h4>
  </LoaderContainer>
);
