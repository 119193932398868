import styled from "styled";
import { Link } from "react-router-dom";

export default styled(Link)`
  text-decoration: none;
  outline: none;
  font-family: "Vibur", sans-serif;
  font-size: 30px;
  color: #00817b;

  @media screen and (min-width: 400px) {
    ::before {
      content: "foodotron";
    }
  }

  @media screen and (max-width: 400px) {
    ::before {
      content: "food";
    }
  }
`;
